<script setup>
import { mdiEye } from '@mdi/js'


const model = defineModel()

const visible = ref(false)
</script>

<template>
  <v-text-field
    v-model="model"
    :type="visible ? 'text' : 'password'"
    v-bind="$attrs"
  >
    <template #append-inner>
      <v-icon
        :icon="mdiEye"
        @mousedown="visible = true"
        @mouseup="visible = false"
        @mouseleave="visible = false"
      />
    </template>
    <template v-for="(_, name) in $slots" #[name]="slotData">
      <slot :name="name" v-bind="slotData" />
    </template>
  </v-text-field>
</template>

<style lang="scss" scoped>

</style>
